import BaseLayout from '@/layout/BaseLayout';

export default [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/team/manage',
    component: BaseLayout,
    children: [
      {
        path: '/team',
        name: 'team',
        children: [
          {
            path: '/team/manage',
            name: 'teamManage',
            component: () => import('@/views/team/manage/index.vue'),
          },
          {
            path: '/team/class',
            name: 'teamClass',
            component: () => import('@/views/team/class/index.vue'),
          },
          {
            path: '/course',
            name: 'course',
            children: [
              {
                path: '/course/online',
                name: 'online',
                component: () => import('@/views/course/online/index.vue'),
              },
              {
                path: '/course/offline',
                name: 'offline',
                component: () => import('@/views/course/offline/index.vue'),
              },
            ],
          },
          {
            path: '/team/teacher',
            name: 'teamTeacher',
            component: () => import('@/views/team/teacher/index.vue'),
          },
          {
            path: '/user/userManage',
            name: 'userManage',
            component: () => import('@/views/user/manage/index.vue'),
          },
        ],
      },
      {
        path: '/activity',
        redirect: '/activity/index',
        children: [
          {
            path: '/activity/index',
            name: 'activity',
            component: () => import('@/views/activity/index/index.vue'),
          },
          {
            path: '/activity/parties',
            name: 'parties',
            component: () => import('@/views/activity/parties/index.vue'),
          },
        ],
      },
    ],
  },
];
