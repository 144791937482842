import { defineStore } from 'pinia';
import { h } from 'vue';
import {
  HomeOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  ReadOutlined,
  BellOutlined,
  UserOutlined,
  TeamOutlined,
  BookOutlined,
  FileSearchOutlined,
  AppstoreOutlined,
  BarsOutlined,
} from '@ant-design/icons-vue';

const useDirectoryStore = defineStore('directory', {
  state: () => ({
    list: [],
    menu: [
      {
        key: 1,
        icon: () => h(HomeOutlined),
        label: '项目管理',
        title: '项目管理',
        children: [
          {
            key: '1-1',
            icon: () => h(BarsOutlined),
            label: '项目',
            title: '项目',
            name: 'teamManage',
          },
          {
            key: '1-2',
            icon: () => h(BarsOutlined),
            label: '活动',
            title: '活动',
            name: 'teamClass',
          },
          {
            key: '1-3',
            icon: () => h(ReadOutlined),
            label: '事件',
            title: '事件',
            children: [
              {
                key: '1-3-1',
                icon: () => h(BarsOutlined),
                label: '线上课',
                title: '线上课',
                name: 'online',
              },
              {
                key: '1-3-2',
                icon: () => h(BarsOutlined),
                label: '线下课',
                title: '线下课',
                name: 'offline',
              },
            ],
          },
          {
            key: '1-4',
            icon: () => h(BarsOutlined),
            label: '教师',
            title: '教师',
            name: 'teamTeacher',
          },
          {
            key: '1-5',
            icon: () => h(UserOutlined),
            label: '用户',
            title: '用户',
            name: 'userManage',
          },
        ],
      },
      {
        key: 3,
        icon: () => h(ReadOutlined),
        label: '六方活动管理',
        title: '六方活动管理',
        children: [
          {
            key: '3-1',
            icon: () => h(BarsOutlined),
            label: '活动',
            title: '活动',
            name: 'activity',
          },
          {
            key: '3-2',
            icon: () => h(BarsOutlined),
            label: '关系方',
            title: '关系方',
            name: 'parties',
          },
        ],
      },
    ],
  }),
  getters: {},
  actions: {
    SET_LIST(list) {
      this.list = list;
    },
  },
});

export default useDirectoryStore;
